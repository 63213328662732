import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import remark from 'remark'
import remarkHTML from 'remark-html'
import Banner from "../components/banner"

import anneImage from "../assets/school/anne-full-length-bio.jpg"
import seytLogo from "../assets/school/seyt-logo.png"
import accreditedLogo from "../assets/school/accredited-logo.jpg"

import firstGalleryImage from "../assets/school/seyt-school-01.jpg"
import secondGalleryImage from "../assets/school/seyt-school-02.jpg"
import thirdGalleryImage from "../assets/school/seyt-school-03.jpg"

import ringsIcon from "../assets/school/rings-icon.svg"

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark

  const bannerUrl = "/school/school-banner.jpg";

  return (
    <Layout title="The School of Embodied Yoga Therapy - Where life’s challenges meet compassionate practice." description="Learn more about Anne's notable involvement with The School of Embodied Yoga Therapy.">
      <Banner title={frontmatter.title} imageUrl={bannerUrl}/>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
               <img src={anneImage} alt="Anne standing" />
            </div>
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.description)}} />
              
            </div>
          </div>
        </div>

        <div className="container" style={{maxWidth:"900px"}}>
          <div className="columns">
            <div className="column" style={{display:"flex", justifyContent:"center"}}>
              <img style={{maxHeight:"120px"}} src={seytLogo} alt="School for embodied yoga therapy logo" />
            </div>
            <div className="column" style={{display:"flex", justifyContent:"center"}}>
              <img style={{maxHeight:"120px"}} src={accreditedLogo} alt="Accredited logo" />
            </div>   
          </div>
        </div>

      </section>
      <section className="section no-top-padding">
        <div className="container content">
          <div className="columns">
            <div className="column">
              <img src={firstGalleryImage} alt="SEYT class in progress" className="pink-border" />
            </div>
            <div class="column">
              <img src={secondGalleryImage} alt="SEYT design made out of scarves" className="pink-border" />
            </div>
            <div class="column">
              <img src={thirdGalleryImage} alt="SEYT outdoor workshop" className="pink-border" />
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-tertiary-faded has-text-centered">
        <div className="container" style={{position: "relative"}}> 
          <div className="archway-container">
            <img src={ringsIcon} alt="Archway" style={{width: "100%", height: "100%", opacity:"0.2"}} />
          </div>     
          <h2 className="has-text-secondary title is-2" style={{maxWidth: "1000px", margin: "auto", position:"relative", zIndex:"3"}}>
            {frontmatter.quote}       
          </h2>
        </div>
      </section>

      <section className="section has-background-tertiary-faded no-top-padding">
        <div className="container" style={{display:"flex"}}>
          <div style={{margin:"auto"}}>
            <div className="columns" style={{alignItems: "flex-end"}}>
              <div className="column">
                <h4 className="title is-4">What is Yoga Therapy?</h4>
                <div className="youtube-container">
                  <iframe className="pink-border youtube"
                  src="https://www.youtube.com/embed/kyFK-dGbf0w" 
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen></iframe>
                </div>
              </div>
              <div className="column">
                <h4 className="title is-4">Welcome to the School of Embodied Yoga Therapy</h4>
                <div className="youtube-container">
                  <video className="pink-border" controls>
                    <source src="/video/SEYT march 2020 v7 - longer book.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>       
          </div>
        </div>
      </section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        description
        quote
      }
    }
  }
`